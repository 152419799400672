import React from "react";
import Link from "gatsby-link";

import {
  Jumbotron,
  Grid,
  Row,
  Col,
  Image,
  Button,
  Container
} from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout.js";
import JobHeader from "../../components/job-header.js";

const SmiialPage = () => (
  <div>
    <Layout>
      <JobHeader
        image="/img/smiial-header4.jpg"
        title="Smiial"
        subtitle="A platform designed for sharing"
        text="Web Design / UI / Print"
      />
      <Container>
        <Row className="show-grid side-padding intro">
          <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
            <h2>My Role</h2>
            <p>
              <em>
                Smiial was a small start up which aim was to connect brands with
                their customers through competitions run through social media.
                My role at Smiial was to help with the development of the
                website design, creating wireframes and working closely with the
                developer to ensure that designs were kept to and that the site
                worked as expected.{" "}
              </em>
            </p>
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
            <Image src="/img/smiial/homepage02_v3.jpg" />
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xs={12} md={4}>
            <Image src="/img/smiial/categoriespage_v3-music.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/Dt_promotion.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/concentive_delivery_v3.jpg" />
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xs={12} md={6}>
            <Image src="/img/smiial/cable_london.jpg" />
          </Col>
          <Col xs={12} md={6}>
            <Image src="/img/smiial/ministry_brandpage_v3.jpg" />
          </Col>
        </Row>

        <Row className="show-grid pt40">
          <Col xs={12} md={8}>
            <Image src="/img/smiial/brandpage_v3.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <h2 className="pt20">Wireframes</h2>
            <p>
              We created wireframes and very low fidelity prototypes to work out
              userflows and ensure that the site was easy to use.
            </p>
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xs={12} md={4}>
            <Image src="/img/smiial/homepage_v3.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/incentiveDelivery_v3.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/listingsTemplate_v3.jpg" />
          </Col>
        </Row>

        <Row className="show-grid">
          <Col xs={12} md={4}>
            <Image src="/img/smiial/profilepage_v3.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/promotionpage_1brand_v3.jpg" />
          </Col>
          <Col xs={12} md={4}>
            <Image src="/img/smiial/promotionpage_2brands_v3.jpg" />
          </Col>
        </Row>
      </Container>
      <UpNext
        image1="/img/marionnaud-card2.jpg"
        title1="Marionnaud"
        text1="A European Health & Beauty brand"
        url1="/marionnaud/"
        image2="/img/superdrug-mobile-card.jpg"
        title2="Superdrug Mobile"
        text2="Creating a mobile brand from scratch"
        url2="/superdrug-mobile/"
        image3="/img/spread-co-card.jpg"
        title3="Spread Co"
        text3="A financial spread betting company"
        url3="/spread-co/"
      />
    </Layout>
  </div>
);

export default SmiialPage;
